import breakpoints from './breakpoints.json'

export const BREAKPOINTS = breakpoints.map((bp) => {
  const queryString = bp.max_width ? `(max-width: ${bp.max_width}px)` : ''
  const mediaQuery = matchMedia(queryString)
  return {
    ...bp,
    isActive: () => mediaQuery.matches,
  }
})

export function getCurrentBreakpoint() {
  // Iterate backwards. Because we use max-width media queries.
  // So the smallest breakpoints should have the most precedence
  return BREAKPOINTS.findLast((bp) => bp.isActive())
}

export function getCurrentBreakpointKey() {
  return getCurrentBreakpoint().key
}
